import './style.css';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import * as dat from 'lil-gui';

/**
 * Base
 */
// Debug
const gui = new dat.GUI({ width: window.innerWidth * 0.95 }).close();

// Canvas
const canvas = document.querySelector('canvas.webgl');

// Scene
const scene = new THREE.Scene();

const parameters = {
    trianglesCount: 30000,
    galaxySize: 1,
    particleSize: 0.007,
    radius: 5,
    branches: 12,
    spin: -0.777,
    randomness: 0.55,
    randomnessPower: 6.239,
    insideColor: '#ff6030',
    outsideColor: '#1b3984',
};

let geometry;
let material;
let galaxy;

const generateGalaxy = () => {
    // Destroy old galaxy
    if (geometry) {
        geometry.dispose();
        material.dispose();
        scene.remove(galaxy);
    }

    geometry = new THREE.BufferGeometry();
    const verticesCount = parameters.trianglesCount * 3;

    const colors = new Float32Array(verticesCount);
    const vertices = new Float32Array(verticesCount);
    const colorInside = new THREE.Color(parameters.insideColor);
    const colorOutside = new THREE.Color(parameters.outsideColor);

    for (let i = 0; i <= verticesCount; i++) {
        const i3 = i * 3;

        const radius = Math.random() * parameters.radius;
        const branchAngle = (Math.PI * 2 * (i % parameters.branches)) / parameters.branches;
        const spinAngle = radius * parameters.spin;

        const randomX =
            Math.pow(Math.random(), parameters.randomnessPower) *
            (Math.random() < 0.5 ? 1 : -1) *
            parameters.randomness *
            radius;
        const randomY =
            Math.pow(Math.random(), parameters.randomnessPower) *
            (Math.random() < 0.5 ? 1 : -1) *
            parameters.randomness *
            radius;
        const randomZ =
            Math.pow(Math.random(), parameters.randomnessPower) *
            (Math.random() < 0.5 ? 1 : -1) *
            parameters.randomness *
            radius;

        vertices[i3] = Math.sin(branchAngle + spinAngle) * radius + randomX; // x
        vertices[i3 + 1] = randomY; // y
        vertices[i3 + 2] = Math.cos(branchAngle + spinAngle) * radius + randomZ; // z

        const mixedColor = colorInside.clone();
        mixedColor.lerp(colorOutside, radius / parameters.radius);

        colors[i3] = mixedColor.r;
        colors[i3 + 1] = mixedColor.g;
        colors[i3 + 2] = mixedColor.b;
    }

    const positionAttribute = new THREE.BufferAttribute(vertices, 3);

    geometry.setAttribute('position', positionAttribute);
    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));

    material = new THREE.PointsMaterial({
        size: parameters.particleSize,
        sizeAttenuation: true,
        depthWrite: false,
        blending: THREE.AdditiveBlending,
        vertexColors: true,
    });

    galaxy = new THREE.Points(geometry, material);

    scene.add(galaxy);
};

const button = {
    generateGalaxy,
};

gui.add(parameters, 'trianglesCount').step(1).min(1).max(50000).onFinishChange(generateGalaxy);
gui.add(parameters, 'galaxySize').step(0.1).min(1).max(50).onFinishChange(generateGalaxy);
gui.add(parameters, 'particleSize').step(0.001).min(0.001).max(0.5).onFinishChange(generateGalaxy);
gui.add(parameters, 'radius').step(0.01).min(0.01).max(20).onFinishChange(generateGalaxy);
gui.add(parameters, 'branches').step(1).min(3).max(20).onFinishChange(generateGalaxy);
gui.add(parameters, 'spin').min(-5).max(5).step(0.001).onFinishChange(generateGalaxy);
gui.add(parameters, 'randomness').min(0).max(2).step(0.001).onFinishChange(generateGalaxy);
gui.add(parameters, 'randomnessPower').min(1).max(10).step(0.001).onFinishChange(generateGalaxy);
gui.addColor(parameters, 'insideColor').onFinishChange(generateGalaxy);
gui.addColor(parameters, 'outsideColor').onFinishChange(generateGalaxy);

generateGalaxy();

/**
 * Test cube
 */
// const cube = new THREE.Mesh(
//     new THREE.BoxGeometry(1, 1, 1),
//     new THREE.MeshBasicMaterial()
// )
// scene.add(cube)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
};

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
camera.position.x = 3;
camera.position.y = 3;
camera.position.z = 3;
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () => {
    const elapsedTime = clock.getElapsedTime();

    if (galaxy) {
        galaxy.rotation.y = -elapsedTime * 0.25;
    }

    // Update controls
    controls.update();

    // Render
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
};

tick();
